import React, { useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import dynamic from 'next/dynamic'
import { ThemeProvider } from '@mui/material/styles'

import LoadingScreen from 'shared-components/common/LoadingScreen'
import { useMediaQuery, useTheme } from '@mui/material'

import { makeStyles } from 'tss-react/mui'

import PageContentWrapper from 'shared-components/common/PageContentWrapper'
import Head from 'next/head'
import DynamicReduxModal from 'components/modals/DynamicReduxModal'
import GetBashCard from '../../components/event/GetBashCard'
import { getIsEventsAdmin, isHostedByPage } from '../../selectors/event'
import HostsCard from '../../components/event/HostsCard'
import apiClient from '../utils/ApiClient'

import { useRouter } from 'next/router'
import { openRsvpOnStep } from '../../actions/rsvp'
import StructuredData from 'components/StructuredData'
import dayjs from 'dayjs'
import PostsSection from '../../components/event/chat/PostsSection'
import EventImage from '../../components/event/EventImage'
import EventDetailsCard from '../../components/event/EventDetailsCard'
import { setSnackbar } from '../redux/notifications/actions'
import { setUsersWithEvents } from '../../actions/event'
import { closeTicketsModal } from 'actions/modals'
import { useUser } from '../../utils/userFunctions'
import { QueryClient } from '@tanstack/react-query'

import Attendees from './Attendees'
import EventLocationCard from '../../components/event/EventLocationCard'
import EventDescriptionCard from '../../components/event/EventDescriptionCard'
import EventSocialProofCard from '../../components/event/EventSocialProofCard'
import EventLineUpCard from 'components/event/EventLineUpCard'
import BottomSheet from '../../components/common/BottomSheet'
import BuyMoreTicketSelection from 'components/RsvpWizard/TicketsStep'
import EditEventHeader from './EditEventHeader'
import GuestManagementModal from '../../components/modals/guestManagement/GuestManagementModal'
import Script from 'next/script'
import { usePreviousRouteContext } from '../../context/PreviousRouteContext'
import PageNotFound from '@pages/404'
import { createCustomTheme, darkTheme, lightTheme } from '../theme'
import { getOgImageTagUrl } from '../../utils/eventFunctions'
import PendingCohostHeader from './PendingCohostHeader'
import DateOptionsModal from './guestList/DateOptionsModal'

const DateOptionsModal = dynamic(
  () => import('shared-components/event/guestList/DateOptionsModal'),
)
const InviteGuestListModal = dynamic(
  () => import('../../components/modals/InviteGuestListModal'),
)
const InviteModal = dynamic(
  () => import('components/modals/Invite/InviteModal'),
)
const ShareModal = dynamic(
  () => import('../../components/modals/Share/ShareModal'),
)
const GuestModal = dynamic(
  () => import('shared-components/event/guestList/GuestModal'),
)
const GeneralFooter = dynamic(
  () => import('../../components/event/GeneralFooter'),
)

const useStyles = makeStyles()((theme) => ({
  mainContainer: {
    width: '100%',
    zIndex: 20,
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    // [theme.breakpoints.up('922')]: {
    //   maxWidth: 600,
    // },
    // '@media (min-width:922px)': {
    //   maxWidth: 440,
    // },
  },
  leftAndRightContainer: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    marginTop: theme.spacing(3),
    width: '100%',
    [theme.breakpoints.down(922)]: {
      maxWidth: 654,
    },
    '@media not all and (min-width:900px)': {
      marginTop: 0,
    },
  },
  rightContainer: {
    flexShrink: 0,
    marginLeft: theme.spacing(6),
    width: 322,
    '& > *': {},
    [theme.breakpoints.down(424)]: {
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  },
  stickyContainer: {
    // position: 'sticky',
    // top: 97,
    maxWidth: '380px',
    width: '100%',
    marginBottom: theme.spacing(4),
  },
  dropZone: {
    zIndex: 500,
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
  },
  ticketsRoot: {
    width: '100%',
    maxWidth: theme.breakpoints.values.container,
    overflow: 'hidden',
    overflowY: 'auto',
    padding: '0px !important',
  },
  eventsInAppCard: {
    background: theme.palette.grey[150],
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(5),
  },
  pageContentWrapper: {
    alignItems: 'center',
  },
}))

export const appContext = React.createContext({})

const getHostName = (host) => host.model.user?.name || host.model.name
const queryClient = new QueryClient()

const PasteImage = dynamic(() => import('./PasteImage'), { ssr: false })

const App = ({
  inDrawer,
  inWidget,
  event,
  organisation,
  isRejected,
  error,
  loading,
  isFulfilled,
  customResponseBar,
  StoreButtons = null,
  useRawAvatarUrl,
  LinkComponent,
  activeGuest,
  posts,
  setPosts,
  t,
  previewType,
  isMobileOrTablet,
  forceShowRsvp,
  redirecting,
}) => {
  const { classes, cx } = useStyles()
  const dispatch = useDispatch()
  const router = useRouter()
  if (organisation) event.organisation = organisation

  const currentTheme = useTheme()
  const [loadingUsersWithEvents, setLoadingUsersWithEvents] = useState(false)
  const [metaPixel, setMetaPixel] = useState<string | null>(null)
  const spotsLeft = event.limitNumberOfGuests
    ? event.expectedNumberOfGuests - event.statusCounts?.going
    : event.expectedNumberOfGuests -
      event.statusCounts?.going -
      event.statusCounts?.maybe
  const limitReached = event.limitNumberOfGuests && spotsLeft < 1
  const [isMounted, setIsMounted] = useState(false)
  const desktopModeFromQueryState = useMediaQuery('(min-width:922px)', {
    noSsr: true,
    defaultMatches: true,
  })
  const isMobileApp = useSelector((state) => state.user.isMobileApp)

  const query = router.query
  const queryAction = query?.action

  const { user, ready, isLoggedIn } = useUser()

  const [savedSharedByUserId, setSavedSharedByUserId] = useState(router.query.u)
  const [savedSharedByGuestId, setSavedSharedByGuestId] = useState(
    router.query.g,
  )
  const hostedByPage = useSelector(isHostedByPage)
  const isEventsAdmin = useSelector(getIsEventsAdmin)

  const pendingCohost = event.hosts?.some(
    (h) =>
      h.state === 'PENDING' &&
      ((h.type === 'ORGANISATION' && h.model.manager) ||
        (h.type === 'USER' && h.model.id === user.id)),
  )

  const ticketsOpen = useSelector((state: any) => state.modals.open.tickets)
  const isOrganiser =
    event.host ||
    (event.type !== 'PUBLIC' &&
      Array.isArray(event.organisers) &&
      event.organisers.some((o) => o.id != null && o.id === user.id))

  const isPublicEvent = event.privacyType === 'PUBLIC' || hostedByPage

  const onTicketsClose = () => {
    dispatch(closeTicketsModal())
  }

  const loadUsersWithEvents = async () => {
    if (event.id) {
      setLoadingUsersWithEvents(true)
      const results = await apiClient.event.getGuestsWithEvents(
        event.id,
        user?.id,
      )
      setLoadingUsersWithEvents(false)
      dispatch(setUsersWithEvents(results))
    }
  }

  useEffect(() => {
    if (!loadingUsersWithEvents && !event.usersWithEvents) {
      loadUsersWithEvents()
    }
  }, [])

  useEffect(() => {
    if (queryAction) {
      if (queryAction === 'PROFILE' && !user.hasAvatar) {
        dispatch(openRsvpOnStep('profile'))
      } else if (queryAction === 'APP') {
        dispatch(openRsvpOnStep('app'))
        dispatch(setSnackbar('success', t('common:emailVerified')))
      }
    }
  }, [queryAction])

  const setCookies = () => {
    const hasConsentedToCookies = localStorage.getItem('cookies') === 'true'

    if (event.code === '5d83mQ1nNX7y' && hasConsentedToCookies) {
      setMetaPixel('4465069517052498')
    } else if (event.code === 'EGegzDFF2qPA' && hasConsentedToCookies) {
      setMetaPixel('587540909308536')
    } else if (event.code === 'vvJW58uIjEzT' && hasConsentedToCookies) {
      setMetaPixel('618990907206989')
    } else if (event.code === 'gPrMJcF2pGnh' && hasConsentedToCookies) {
      setMetaPixel('3834978916823833')
    } else if (event.code === 'UdzB1oOaIw31' && hasConsentedToCookies) {
      setMetaPixel('1154092356263252')
    } else {
      setMetaPixel(null)
    }
  }

  useEffect(() => {
    setCookies()
  }, [event.code])

  // Add listener for cookie consent changes
  useEffect(() => {
    const handleStorageChange = () => {
      setCookies()
    }

    window.addEventListener('storage', handleStorageChange)
    return () => window.removeEventListener('storage', handleStorageChange)
  }, [event.code])

  useEffect(() => {
    setIsMounted(true)
  }, [])

  const desktopMode =
    previewType === 'mobile'
      ? false
      : inDrawer
        ? true
        : !isMounted
          ? !isMobileOrTablet
          : desktopModeFromQueryState

  const app = {
    event,
    limitReached,
    spotsLeft,
    isPublicEvent,
    pinningDate: event.type === 'PINNING',
    StoreButtons,
    LinkComponent,
    isOrganiser,
    activeGuest,
    desktopMode,
    inWidget,
  }

  const previousLocation = usePreviousRouteContext()

  useEffect(() => {
    if (event.id) {
      const sharedByUserId = router.query.u
      const sharedByGuestId = router.query.g
      setSavedSharedByUserId(sharedByUserId)
      setSavedSharedByGuestId(sharedByGuestId)
      apiClient.event.viewEvent(
        event.id,
        'event_page',
        sharedByUserId,
        sharedByGuestId,
        event.myGuest?.id,
        isMobileApp || router.query.isMobileApp
          ? 'app'
          : previousLocation?.length !== undefined &&
              previousLocation?.length > 0
            ? previousLocation
            : null,
        query.utm_medium,
        query.utm_campaign,
        query.utm_source,
      )
    }
  }, [event.id])

  useEffect(() => {
    // @ts-expect-error checking if undefined
    if (metaPixel && window.fbq) {
      // Initialize Meta Pixel
      // @ts-expect-error checking if undefined
      window.fbq('init', metaPixel)
      // @ts-expect-error checking if undefined
      window.fbq('track', 'PageView')

      // Cleanup function
      return () => {
        // @ts-expect-error checking if undefined
        delete window.fbq
        // Remove the script tag
        const scriptTag = document.getElementById('meta-pixel')
        if (scriptTag) scriptTag.remove()
        // Remove noscript pixel image
        const noscriptPixel = document.querySelector(`img[src*="${metaPixel}"]`)
        if (noscriptPixel) noscriptPixel.remove()
      }
    }
  }, [metaPixel])

  const themeQuery = router.query.theme

  // Create custom theme if event has a theme
  const customTheme = useMemo(() => {
    if (currentTheme.extras?.overrideAll) return currentTheme
    if (themeQuery === 'dark') return darkTheme
    if (themeQuery === 'light') return lightTheme
    if (!event.theme) return lightTheme
    return createCustomTheme(event.theme)
  }, [event.theme, themeQuery, currentTheme])

  const getDescription = () => {
    if (event?.description) return event.description
    if (event?.startDate && event?.location) {
      return `${dayjs(event.startDate).format('DD/MM/YYYY')} - ${event.location}`
    }
    if (event?.startDate) {
      return `${dayjs(event.startDate).format('DD/MM/YYYY')}`
    }
    return t('common:inviteViaBASH')
  }

  if (!isFulfilled || redirecting)
    return (
      <LoadingScreen
        isRejected={isRejected}
        coverAll={undefined}
        isOrg={undefined}
        isUser={undefined}
      />
    )
  if (error || isRejected) return <PageNotFound />

  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'Event',
    name: event.name,
    ...(event.startDate && {
      startDate: dayjs(event.startDate).format('YYYY-MM-DDTHH:MM'),
    }),
    ...(event.endDate && {
      endDate: dayjs(event.endDate).format('YYYY-MM-DDTHH:MM'),
    }),
    ...((event.location || event.address) && {
      location: {
        '@type': 'Place',
        name: event.location ?? event.address,
        ...(event.address && {
          address: {
            '@type': 'PostalAddress',
            streetAddress: event.address,
          },
        }),
      },
    }),
    ...(event.imageUrls?.lg && {
      image: [event.imageUrls.lg],
    }),
    ...(event.description && {
      description: event.description,
    }),
    ...(event.hosts &&
      event.hosts[0] && {
        performer: {
          '@type': 'PerformingGroup',
          name: getHostName(event.hosts[0]),
        },
      }),
  }

  const getOgImageTag = () => {
    return getOgImageTagUrl(
      event,
      false,
      event.invitedBy == null || event.privacyType === 'PRIVATE'
        ? {
            id: savedSharedByUserId,
          }
        : null,
      true,
      false,
    )
  }

  return (
    <appContext.Provider value={app}>
      <ThemeProvider theme={customTheme}>
        {metaPixel && (
          <>
            <Script id='meta-pixel'>
              {`
        !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
      `}
            </Script>
            <noscript>
              <img
                height='1'
                width='1'
                // @ts-expect-error checking if undefined
                style='display:none'
                src={`https://www.facebook.com/tr?id=${metaPixel}&ev=PageView&noscript=1`}
              />
            </noscript>
          </>
        )}
        <Head>
          <meta
            name='description'
            content={getDescription()}
            key='description'
          />
          {!inDrawer && !inWidget && (
            <title>
              {event.name || t('createEvent') + ' · ' + t('bashSocialEvents')}
            </title>
          )}
          <meta
            key='ogImage'
            property='og:image'
            itemProp='image'
            // content={event?.imageUrls?.lg || '/android-chrome-192x192.png'}
            content={getOgImageTag()}
          />
          <meta
            key='ogDescription'
            property='og:description'
            content={getDescription()}
          />
          <link
            rel='apple-touch-icon'
            sizes='180x180'
            href='/apple-touch-icon.png'
            media='(prefers-color-scheme: light)'
          />
          <link
            rel='apple-touch-icon'
            sizes='180x180'
            href='/apple-touch-icon-white.png'
            media='(prefers-color-scheme: dark)'
          />
          <link
            rel='icon'
            type='image/png'
            sizes='32x32'
            href='/favicon-32x32.png'
            media='(prefers-color-scheme: light)'
          />
          <link
            rel='icon'
            type='image/png'
            sizes='32x32'
            href='/favicon-32x32-white.png'
            media='(prefers-color-scheme: dark)'
          />
          <link
            rel='icon'
            type='image/png'
            sizes='16x16'
            href='/favicon-16x16.png'
            media='(prefers-color-scheme: light)'
          />
          <link
            rel='icon'
            type='image/png'
            sizes='16x16'
            href='/favicon-16x16-white.png'
            media='(prefers-color-scheme: dark)'
          />
          <link rel='manifest' href='/site.webmanifest' />
          <link
            rel='mask-icon'
            href='/safari-pinned-tab.svg'
            color='#fafafa'
            media='(prefers-color-scheme: light)'
          />
          <link
            rel='mask-icon'
            href='/safari-pinned-tab-white.svg'
            color='#ffffff'
            media='(prefers-color-scheme: dark)'
          />
          <link
            rel='shortcut icon'
            href='/favicon.ico'
            media='(prefers-color-scheme: light)'
          />
          <link
            rel='shortcut icon'
            href='/favicon-white.ico'
            media='(prefers-color-scheme: dark)'
          />
          <meta
            key='ogTitle'
            property='og:title'
            content={event?.name || t('common:inviteViaBASH')}
          />
          {isPublicEvent && (
            <>
              <meta
                key='ogUrl'
                property='og:url'
                content={`${process.env.NEXT_PUBLIC_WEBSITE}${router.asPath}`}
              />
            </>
          )}
        </Head>
        {isPublicEvent && <StructuredData data={structuredData} />}
        <style jsx global>
          {`
            body {
              background: ${customTheme?.palette.background.default || '#fff'}
                ${inDrawer ? '' : '!important'};
            }

            html {
              color-scheme: ${event.theme.darkMode ? 'dark' : 'light'};
            }
          `}
        </style>
        <PasteImage />

        <PageContentWrapper
          id={event.id}
          large
          className={classes.pageContentWrapper}
          small={undefined}
          fullHeight={undefined}
          fullHeightWithHeader={undefined}
          padding={undefined}
          fullWidth={undefined}
          blurred={undefined}
        >
          {!pendingCohost &&
            !inDrawer &&
            !inWidget &&
            (event.host === true || (isEventsAdmin && hostedByPage)) && (
              <EditEventHeader event={event} />
            )}
          {!inDrawer && !inWidget && pendingCohost && (
            <PendingCohostHeader event={event} />
          )}
          <div
            className={cx(classes.leftAndRightContainer)}
            style={{
              marginTop: inDrawer || inWidget ? '0px' : undefined,
              color: customTheme?.palette.text.primary,
            }}
          >
            <div className={cx(classes.mainContainer)}>
              <EventDetailsCard
                inDrawer={inDrawer}
                inWidget={inWidget}
                desktopMode={desktopMode}
                forceShowRsvp={forceShowRsvp}
              />

              {(!desktopMode || inDrawer) && (
                <EventSocialProofCard desktopMode={desktopMode} />
              )}

              {/* {event.type === 'PINNING' && (
                    <SelectDate desktopMode={desktopMode} />
                  )} */}

              {/* <EventQuestionsCard desktopMode={desktopMode} /> */}

              {(!desktopMode || inDrawer) &&
                event.hosts &&
                event.hosts.length > 0 && (
                  <HostsCard desktopMode={desktopMode} />
                )}

              {(!desktopMode || inDrawer) && (
                <EventLineUpCard desktopMode={desktopMode} />
              )}

              {(!desktopMode || inDrawer) && (
                <Attendees desktopMode={desktopMode} />
              )}

              <EventLocationCard desktopMode={desktopMode} />

              <EventDescriptionCard
                desktopMode={desktopMode}
                previewType={undefined}
              />

              {user.id || inDrawer ? null : (
                <GetBashCard useHeader={undefined} />
              )}
              {/*{!isExternal && (*/}
              <PostsSection
                key={`posts-${event.id}`}
                desktopMode={desktopMode}
                items={posts}
                setItems={setPosts}
              />
              {/*)}*/}
            </div>
            {desktopMode && !inDrawer && (
              <div className={classes.rightContainer}>
                <div className={classes.stickyContainer}>
                  <div className={classes.imageContainer}>
                    <EventImage />
                  </div>
                  {desktopMode && (
                    <EventSocialProofCard desktopMode={desktopMode} />
                  )}
                  {desktopMode && event.hosts && event.hosts.length > 0 && (
                    <HostsCard desktopMode={desktopMode} />
                  )}
                  {desktopMode && <EventLineUpCard desktopMode={desktopMode} />}
                  {desktopMode && <Attendees desktopMode={desktopMode} />}
                </div>
              </div>
            )}
          </div>
          {event.type === 'PINNING' && <DateOptionsModal />}
          {/*<DynamicReduxModal*/}
          {/*  reduxName='inviteGuests'*/}
          {/*  DynamicComponent={InviteGuestsModal}*/}
          {/*/>*/}

          <DynamicReduxModal
            reduxName='inviteGuests'
            DynamicComponent={InviteModal}
          />
          <DynamicReduxModal
            reduxName='guestList'
            DynamicComponent={InviteGuestListModal}
          />
          <DynamicReduxModal reduxName='share' DynamicComponent={ShareModal} />
          <DynamicReduxModal reduxName='guest' DynamicComponent={GuestModal} />
          <GuestManagementModal />
          <BottomSheet
            className={classes.ticketsRoot}
            open={ticketsOpen}
            onClose={onTicketsClose}
            title={undefined}
            onBack={undefined}
            disableBackdropClick={undefined}
            fullScreen={undefined}
            topRightContent={undefined}
            contentClassName={undefined}
            maxWidth={undefined}
          >
            <BuyMoreTicketSelection />
          </BottomSheet>
        </PageContentWrapper>
        {!inDrawer && !inWidget && <GeneralFooter maxWidth={undefined} />}
      </ThemeProvider>
    </appContext.Provider>
  )
}

export default withTranslation('shared')(App)
